import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

class MaterialComponentList extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Button variant="contained" color="primary">
          Primary
        </Button>
      </React.Fragment>
    );
  }
}

export default MaterialComponentList;
